<template>
  <div class="wrapper">
    <!-- 机构管理页面 -->
    <h4>{{ titleName }}</h4>
    <el-form :model="form" ref="form" :rules="rules" label-width="auto">
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="机构名称" prop="orgName">
            <el-input v-model="form.orgName" placeholder="请输入机构名称" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="是否配送" prop="delivery">
            <el-select v-model="form.delivery" placeholder="是否配送" style="width: 100%">
              <el-option v-for="item in deliveryList" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="配送费用">
            <el-input v-model="form.deliveryFee" placeholder="输入配送费用" type="number" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="联系电话" prop="mainPersonTel">
            <el-input v-model="form.mainPersonTel" placeholder="请输入联系电话" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="负责人">
            <el-input v-model="form.mainPerson" placeholder="请输入负责人" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="服务时间">
            <el-date-picker v-model="form.serviceTime" value-format="timestamp" type="datetime" placeholder="选择日期时间" style="width: 100%"></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="助餐标准">
            <el-input v-model="form.standard" placeholder="请输入助餐标准" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="状态">
            <el-select v-model="form.status" placeholder="请选择状态" style="width: 100%" clearable>
              <el-option :value="item.value" :label="item.label" v-for="item of stateList" :key="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="承办机构">
            <el-input v-model="form.beOrgName" placeholder="请输入承办机构" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="机构地址">
            <el-input v-model="form.address" placeholder="请输入机构地址" auto-complete="off"></el-input>

            <!-- <el-select v-model="form.area" placeholder="请选择区县" @change="areaChange" style="width: 100%">
              <el-option v-for="item in areaList" :key="item" :label="item" :value="item"></el-option>
            </el-select> -->
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="经度" prop="longitude">
            <el-input v-model="form.longitude" placeholder="请输入经度" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="纬度" prop="latitude">
            <el-input v-model="form.latitude" placeholder="请输入纬度" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
        <!-- <el-col :span="6">
          <el-form-item label-width="0">
            <el-select v-model="form.street" placeholder="请选择街道" style="width: 100%">
              <el-option v-for="item in streetList" :key="item" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label-width="0">
            <el-input v-model="form.address" placeholder="请输入详细地址" auto-complete="off"></el-input>
          </el-form-item>
        </el-col> -->
      </el-row>
            <!-- <el-row :gutter="20">

      </el-row> -->
            <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="营业执照图片" prop="businessLicense">
            <upload-img :limit="1" :uploadLists="businessLicenseList" @uploadChange="uploadSuccess1"></upload-img>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="公司从业资质图片" prop="professionalQualification">
            <upload-img :limit="1" :uploadLists="professionalQualificationList" @uploadChange="uploadSuccess2"></upload-img>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item style="text-align: center" label-width="0">
            <el-button type="primary" icon="fa fa-check" @click="submitEvent('form')">保存</el-button>
            <el-button icon="fa fa-undo" @click="$router.go(-1)">返回</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import UploadImg from '@/components/UploadImg.vue'
import api from '@/store/API/api'
import axios from 'axios'

export default {
  name: 'AddOrEdit',
  components: {
    UploadImg,
  },
  data() {
    return {
      businessLicenseList: [],
      professionalQualificationList: [],
      titleName: '',
      form: {
        city: '西安市',
        beOrgId: 123,
      },
      tablePage: {
        totalResult: 0,
        currentPage: 0,
        pageSize: 10,
        align: 'left',
        pageSizes: [10, 20, 50, 100, 200, 500, 1000],
        perfect: true,
      },
      plateNoId: '',
      rules: {
        name: [{ required: true, message: '请输入活动名称', trigger: 'blur' }],
        mainPersonTel: [{ pattern: /^(1[3456789]\d{9})$/, message: '请检查联系电话格式' }],
      },
      areaList: [],
      streetList: [],
      stateList: [
        {
          label: '建设中',
          value: '0',
        },
        {
          label: '已建成',
          value: '1',
        },
        {
          label: '运营中',
          value: '2',
        },
        {
          label: '停业中',
          value: '3',
        },
      ],
      deliveryList: [
        {
          value: '0',
          label: '是',
        },
        {
          value: '1',
          label: '否',
        },
      ],
    }
  },
  computed: {
    routeType() {
      return this.$route.params.type
    },
    searchist() {
      var list = []
      var _list = []
      var _this = this
      this.seqList.map(function(item) {
        if (item.plateNo.indexOf(_this.plateNoId) != -1) {
          //然后把当前json添加到list数组中
          _list.push(item)
          list = _list
        }
      })
      return list
    },
  },
  created() {
    this.initView()
  },
  methods: {
    ...mapActions(['addmealOrg', 'updateWorkPersonServe', 'getAreasServe', 'getStreetsServe', 'mealOrgdetail']),
    focusEvent1() {
      this.$refs.xDown1.showPanel()
    },
    // 获取区县list
    getAreaList(val) {
      let params = {
        parenName: val,
      }
      this.getAreasServe(params).then((res) => {
        if (res.code == 200) {
          this.areaList = res.data
        }
      })
    },
    areaChange(val) {
      if (val) {
        this.getStreetList(val)
      }
    },
    // 获取街道list
    getStreetList(val) {
      let params = {
        parenName: val,
      }
      this.getStreetsServe(params).then((res) => {
        if (res.code == 200) {
          this.streetList = res.data
        }
      })
    },
    //获取水泥车数据
    plateNoClick(item) {
      this.form.plateNoId = item.id
      this.plateNoId = item.plateNo
      this.$refs.xDown1.hidePanel()
    },
    getLeaveTime(data) {
      this.form.leaveTime = data
    },
    getFactTime(data) {
      this.form.factTime = data
    },
    initView() {
      this.getAreaList('西安市')
      if (this.routeType == 'add') {
        this.titleName = '添加机构信息'
      } else {
        this.titleName = '修改机构信息'
        this.itemData = JSON.parse(this.$route.query.item)
        let params = {
          id: this.itemData.id,
        }
        let data = null
        let server = 'mealOrgdetail'
        this[server](params).then((res) => {
          if (res.code == 200) {
            data = res.data
            this.form = {
              address: data.address,
              area: data.area,
              beOrgId: data.beOrgId,
              beOrgName: data.beOrgName,
              city: data.city,
              delivery: data.delivery,
              deliveryFee: data.deliveryFee,
              id: this.itemData.id,
              mainPerson: data.mainPerson,
              mainPersonTel: data.mainPersonTel,
              orgName: data.orgName,
              serviceTime: data.serviceTime,
              standard: data.standard,
              status: data.status,
              street: data.street,
              businessLicense: data.businessLicense,
              professionalQualification: data.professionalQualification,
              longitude: data.longitude,
              latitude: data.latitude,
            }
            if (data.city) {
              this.getAreaList(data.city)
            }
            if (data.area) {
              this.getStreetList(data.area)
            }
            if (data.businessLicense) {
              this.getFileDetail1(data.businessLicense)
            }
            if (data.professionalQualification) {
              this.getFileDetail2(data.professionalQualification)
            }
          }
        })
      }
    },
    getFileDetail1(ids) {
      ids = ids.split(',')
      ids.forEach((item) => {
        axios({
          method: 'get',
          data: {},
          url: '/api' + api.fileDetail + '/' + item,
          responseType: 'blob',
          headers: {
            Authorization: sessionStorage.token,
          },
        }).then((res) => {
          this.businessLicenseList.push({
            id: item,
            url: window.URL.createObjectURL(res.data),
          })
        })
      })
    },
    getFileDetail2(ids) {
      ids = ids.split(',')
      ids.forEach((item) => {
        axios({
          method: 'get',
          data: {},
          url: '/api' + api.fileDetail + '/' + item,
          responseType: 'blob',
          headers: {
            Authorization: sessionStorage.token,
          },
        }).then((res) => {
          this.professionalQualificationList.push({
            id: item,
            url: window.URL.createObjectURL(res.data),
          })
        })
      })
    },
    uploadSuccess1(data) {
      this.form.businessLicense = data.join(',')
    },
    uploadSuccess2(data) {
      this.form.professionalQualification = data.join(',')
    },
    //区域选择
    selectArea(data) {
      this.form.teamName = ''
      this.teamByAreaList = ''
      this.getTeamListByArea(data) //获取队伍
    },
    submitEvent(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {}
          params = this.form
          let server = this.routeType == 'add' ? 'addmealOrg' : 'addmealOrg'
          this[server](params).then((res) => {
            if (res.code == 200) {
              this.$XModal.message({
                message: this.routeType == 'add' ? '添加成功' : '修改成功',
                status: 'success',
              })
              this.$router.go(-1)
            }
            this.loading = false
          })
        } else {
          return false
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 10px;
  overflow: hidden;
  h4 {
    height: 40px;
    line-height: 40px;
    color: #333;
    padding-left: 20px;
    margin-bottom: 20px;
  }

  .vxe-form {
    padding: 25px 0;
    overflow: auto;
  }
  .my-dropdown1 {
    height: 200px;
    overflow: auto;
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid #dcdfe6;
    padding: 0 8px;
    li {
      height: 32px;
      line-height: 32px;
      cursor: pointer;
    }
  }
}
</style>
